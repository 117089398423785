'use strict';
var itoc = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=';
var ctoi = {};

for (var index = 0; index < 66; index++) ctoi[itoc.charAt(index)] = index;

module.exports = {
  itoc: itoc,
  ctoi: ctoi
};
